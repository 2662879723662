import React from "react"

import { graphql } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import { useAboutData } from "hooks/contentful/pages/useAboutData"

import Layout from "components/Layout"
import SEO from "components/Seo"
import PageTitle from "components/PageTitle/PageTitle"
import AboutCardA from "components/AboutCards/AboutCardA"
import AboutCardB from "components/AboutCards/AboutCardB"
import AboutCardC from "components/AboutCards/AboutCardC"

import "../styles/main.scss"
import styles from "../styles/pages/about.module.scss"

const AboutPalforzia = ({ data }) => {
  const {
    header,
    aboutA,
    aboutB,
    aboutC,
    referencesHeader,
    references,
  } = useAboutData(data.page)

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout referencesHeader={referencesHeader} references={references}>
        <SEO title={data.page.title} description={data.page.description} />
        <main className={styles.content}>  
          <PageTitle title={header.title} references={[1]} ></PageTitle>
          <AboutCardA {...aboutA} />
          <AboutCardB {...aboutB} />
          <AboutCardC {...aboutC} />
        </main>
      </Layout>
    </StateProvider>
  )
}

export default AboutPalforzia

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "About Palforzia" }) {
      label
      url
      title
      description
      header {
        ...ReferencedTitleFragment
      }
      content {
        ...AboutAssemblyAV2Fragment
        ...AboutAssemblyBFragment
        ...AboutAssemblyCFragment
      }
      referencesHeader {
        ...ReferencedTitleFragment
      }
    }
  }
`
