import React from "react"

import { Container } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

import Image from "../Image"

import styles from "./AboutCardB.module.scss"

const AboutCardB = ({ title, topicB, figure, alt, disclaimer }) => {

  const RENDER_CHECK_CONFIG = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        console.log('node ', node);
        console.log('topicB ', topicB);
        return (
          <p className={styles.topic}>
            {children}
            {node.references &&
              <sup>{node.references.join(",")}</sup>}
          </p>
        )
      },
    },
  }

  return (
    <Container fluid className={styles.card}>
      <Container>
        <div className={styles.flexContainer}>
          <div className={styles.copyContainer}>
            <h3 className={styles.header}>
              {title.title}
            </h3>
            {documentToReactComponents(topicB.content, RENDER_CHECK_CONFIG)}
          </div>
          <Image alt={"Dose preparation icon"} node={figure} className={styles.image} />
        </div>
      </Container>
    </Container>
  )
}
export default AboutCardB
