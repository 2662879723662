import React from "react"

import { Container } from "react-bootstrap"

import Image from "../Image"
import CTAArrow from "../../assets/svg/cta-arrow"

import styles from "./AboutCardC.module.scss"

const AboutCardC = ({ title, content }) => {
  
  return (
    <Container fluid className={styles.card}>
      <Container className={styles.aboutCardContainer}>
        <nav className={styles.flexContainer}>
          <h3 className={styles.header}>{title}</h3>
          <div className={styles.ctaContainer}>
            {content.map(({ figure, caption, cta, alt }, index) => {
              return (
                <div className={styles.cta} key={index}>
                  <Image className={styles.figure} node={figure} alt={alt} />
                  <div className={styles.buttonContainer}>
                    <p className={styles.caption}>{caption}</p>
                    <a className={styles.button} href={cta.action.url}>
                      {cta.label}
                      <CTAArrow classes={styles.icon} />
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </nav>
      </Container>
    </Container>
  )
}
export default AboutCardC
