import {
  HCP_ABOUT_ASSEMBLY_A,
  HCP_ABOUT_ASSEMBLY_AV2,
  HCP_ABOUT_ASSEMBLY_B,
  HCP_ABOUT_ASSEMBLY_C,
} from "constants/ContentfulContentTypes"
import { FOOTNOTE_SYMBOLS } from "constants/Site"

const mapHeader = ({ title, references }, pageReferences) => {
  return {
    title,
    references: references.map(
      (reference) => pageReferences.indexOf(reference.title) + 1
    ),
  }
}

const mapTopic = (topic, pageReferences = [], footnotes = []) => {
  return {
    content: topic.richContent?.json,
    figure: topic.figure && {
      value: topic.figure?.value,
      unit: topic.figure?.unit,
    },
    header: topic.title,
    footnote:
      topic.footnote && FOOTNOTE_SYMBOLS[footnotes.indexOf(topic.footnote)],
    references:
      topic.references &&
      topic.references.map(
        (reference) => pageReferences.indexOf(reference.title) + 1
      ),
  }
}

const mapTopics = (topics, pageReferences, footnotes) => {
  return topics.map((topic) => mapTopic(topic, pageReferences, footnotes))
}


const getReferences = (header, assemblyB) => {
  const references = []

  references.push(
    ...header.references
      .map((reference) => reference.title)
      .filter((reference) => references.indexOf(reference) === -1)
  )

  return references
}

export const useAboutData = ({ content, referencesHeader, header }) => {
  const assemblyA = content.find(
    (item) => item.__typename === HCP_ABOUT_ASSEMBLY_AV2
  )

  const assemblyB = content.find(
    (item) => item.__typename === HCP_ABOUT_ASSEMBLY_B
  )

  const assemblyC = content.find(
    (item) => item.__typename === HCP_ABOUT_ASSEMBLY_C
  )

  const references = getReferences(header, assemblyB)

  references.push('Bird JA, Spergel JM, Jones SM, et al. J Allergy Clin Immunol Pract. 2018;6(2):476-485.e3.')

  console.log('references ', references);

  // Hardcoding references for now
  let topicB = mapTopic(assemblyB.topicB);
  topicB.content.content[0].references = [1,2];
  topicB.content.content[1].references = ['2'];
  topicB.content.content[2].references = [1,2];

  return {
    header: header,
    aboutA: {
      title: assemblyA.title,
      topicA: mapTopic(assemblyA.topicA),
    },
    aboutB: {
      title: assemblyB.header,
      topicB: topicB,
      disclaimer: mapTopic(assemblyB.disclaimer),
      figure: assemblyB.figure.localFile,
    },
    aboutC: {
      title: assemblyC.title,
      content: assemblyC.content.map(({ cta, figure, title, alt }) => {
        return {
          caption: title,
          figure: figure.localFile,
          alt: alt,
          cta: {
            ...cta,
          },
        }
      }),
    },
    referencesHeader: referencesHeader.title,
    references,
  }
}
