import React from "react"

import { Container } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import classNames from "classnames"
import { FOOTNOTE_SYMBOLS } from "constants/Site"

import Caption from "components/Caption/Caption"

import styles from "./AboutCardA.module.scss"

const Topic = ({ figure, content, footnote, references, index }) => {
  return (
    <div className={styles.topic}>
      <div className={styles.figure}>
        <div className={styles.figureValue}>
          <span
            className={classNames([styles.value], {
              [styles.textValue]: figure?.unit === null,
              [styles.smallText]: index === 1,
            })}
          >
            {figure?.value}
          </span>
          {figure?.unit && <sub className={styles.unit}>{figure?.unit}</sub>}
        </div>
      </div>
      {documentToReactComponents(content, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
              <p>
                {children}
                {footnote && <sup className={styles.footnote}>{footnote}</sup>}
                <sup className={styles.reference}>{references.join(",")}</sup>
              </p>
            )
          },
        },
      })}
    </div>
  )
}

const AboutCardA = ({
  title,
  topic,
  topicA
}) => {
  console.log('topicA ', topicA)
  return (
    <Container>
      <div className={styles.card}>
        <h2 className={styles.header}>{title}</h2>
        <ul>{documentToReactComponents(topicA.content, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
              <li>
                {children}
              </li>
            )
          },
        },
      })}</ul>

      </div>
    </Container>
  )
}
export default AboutCardA
